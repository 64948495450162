<template>
  <div class="animated fadeIn">
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :background-color="this.$config.LOADER.backgroundColor"
      :z-index="10000"
    ></loading>
    <b-row>
      <b-col sm="4">
        <b-card>
          <b-form-group
            id="input-group-name"
            label="Огноо:"
            label-for="input-name"
            description="Хамгийн ихдээ 90 хоног сонгоно уу"
          >
            <date-range-picker
              class="w-100"
              v-model="dateRange"
              :locale-data="locale"
              :opens="opens"
              :autoApply="true"
              :ranges="ranges"
              :timePicker="false"
            >
            </date-range-picker>
          </b-form-group>
          <b-form-group label="Төрөл">
            <b-select v-model="selected_option">
              <option :value="0" disabled selected>-- Сонгоно уу --</option>
              <option
                :value="item.value"
                :key="index"
                v-for="(item, index) in options"
                >{{ item.text }}</option
              >
            </b-select>
          </b-form-group>
          <b-button @click="showData()">Харах</b-button>
        </b-card>
      </b-col>
      <b-col sm="8">
        <b-card>
          <div v-if="data.length > 0">
            <b-table :fields="fields" striped hover :items="data"></b-table>
          </div>
          <b-alert variant="warning" v-else show class="mb-0">
            Мэдээлэл байхгүй байна
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css";

export default {
  name: "Report.list.month",
  components: {
    DateRangePicker,
    axios,
  },
  data: function() {
    return {
      isLoading: false,
      today: moment().format("YYYY-MM-DD"),
      alwaysShowCalendars: false,
      linkedCalendars: false,
      ranges: {
        Өнөөдөр: [moment(), moment()],
        Өчигдөр: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Сүүлийн 30 хоног": [moment().subtract(29, "days"), moment()],
        "Сүүлийн 90 хоног": [moment().subtract(89, "days"), moment()],
        "Энэ сар": [moment().startOf("month"), moment().endOf("month")],
        "Өмнөх сар": [
          moment()
            .subtract(1, "month")
            .startOf("month"),
          moment()
            .subtract(1, "month")
            .endOf("month"),
        ],
      },
      dateRange: {
        startDate: moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      opens: "right",
      locale: {
        separator: " - ",
        format: "YYYY-MM-DD",
        customRangeLabel: "Өдөр сонгох",
        daysOfWeek: ["Ня", "Да", "Мя", "Лх", "Пү", "Ба", "Бя"],
        monthNames: [
          "1-р сар",
          "2-р сар",
          "3-р сар",
          "4-р сар",
          "5-р сар",
          "6-р сар",
          "7-р сар",
          "8-р сар",
          "9-р сар",
          "10-р сар",
          "11-р сар",
          "12-р сар",
        ],
        firstDay: 1,
      },
      options: [
        { value: 1, text: "Зээлийн дүн" },
        // { value: 2, text: 'Зээлийн дүн 7 хоногоор' },
        { value: 2, text: "Зээлийн хүү" },
        // { value: 4, text: 'Зээлийн хүү 7 хоногоор' },
        { value: 3, text: "Төлөлт" },
        { value: 4, text: "Хэрэглэгч" },
        { value: 5, text: "Хэрэглэгчийн төлөв" },
      ],
      selected_option: 0,
      data: [],
      fields: [],
      headers: {
        "1": [
          { key: "0", label: "Он" },
          { key: "1", label: "Сар" },
          { key: "2", label: "Эхний зээл" },
          { key: "3", label: "" },
          { key: "4", label: "Дахин авсан зээл" },
          { key: "5", label: "" },
          { key: "6", label: "Нийт" },
          { key: "7", label: "" },
        ],
        "2": [
          { key: "0", label: "Он" },
          { key: "1", label: "Сар" },
          { key: "2", label: "Жигнэсэн дундаж зээлийн хүү" },
          { key: "3", label: "Хаагдсан зээлийн дундаж хугацаа" },
          { key: "4", label: "Үлдэгдэлтэй байгаа зээлийн дундаж хугацаа" },
        ],
        "3": [
          { key: "0", label: "Хугацаа хэтрүүлтээс төлсөн" },
          { key: "1", label: "Хаалт" },
          { key: "2", label: "Эргэн төлөлт" },
        ],
        "4": [
          { key: "0", label: "Бүртгүүлсэн" },
          { key: "1", label: "Гэрээ байгуулсан" },
        ],
      },
    };
  },
  computed: {
    getStartDate() {
      return moment(this.dateRange.startDate).format("YYYY-MM-DD");
    },
    getEndDate() {
      return moment(this.dateRange.endDate).format("YYYY-MM-DD");
    },
  },
  methods: {
    showData: async function() {
      if (this.$data.selected_option) {
        this.$data.isLoading = true;
        var response = await this.$http.get(
          this.$config.API_URL + "ReportWebService/get_weekly",
          {
            params: {
              startDate: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
              endDate: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
              type: parseInt(this.$data.selected_option),
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        );
        this.$data.isLoading = false;
        //console.log(response)
        if (response.data.status == 500) {
          showToast("Алдаа", "Алдаа гарсан байна. Дахин оролдоно уу", "danger");
        } else {
          this.$data.data = response.body.data;
          this.$data.fields = this.$data.headers[this.$data.selected_option];
        }
      } else {
        showToast("warning", "Төрөл сонгоогүй байна", "danger");
      }
    },
  },
};
</script>
